.link {
  text-transform: uppercase;
  color: #6fc04d;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  padding: 5px 17px;
  border-radius: 4px;
  border: none;
  background: none;
  box-shadow: none;

  &:hover {
    color: #5baf38;
    background: none;
  }

  &:focus,
  &:active {
    color: #6fc04d;
    background: #f8f9f9;
  }

  img {
    fill: #5baf38;
  }

  &:global(.white) {
    color: white;

    &:hover,
    &:focus,
    &:active {
      color: white;
      background: none;
    }
  }

  &:global(.red) {
    color: #FB7373;

    &:hover,
    &:focus,
    &:active {
      color: #FB7373;
      background: none;
    }
  }
}

.primary {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #ffffff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 27px;
  background: linear-gradient(270deg, #6ebf4c 0%, #78cc54 104.24%);
  box-shadow: 0px 4px 34px rgba(24, 24, 24, 0.17);
  border: none;

  &:hover {
    background: linear-gradient(270deg, #5cae39 0%, #65ba41 104.24%);
    box-shadow: 0px 4px 34px rgba(24, 24, 24, 0.17);
  }

  &:focus,
  &:active {
    background: linear-gradient(270deg, #5cae39 0%, #65ba41 104.24%);
    box-shadow: 0px 4px 24px rgba(24, 24, 24, 0.24);
  }
}

.danger {
  color: #ffffff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;
  background: linear-gradient(270deg, #FB7373 0%, #FD5C5C 104.24%);
  box-shadow: 0px 4px 34px rgba(24, 24, 24, 0.17);
  border: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;

  &:hover {
    opacity: 0.8;
    box-shadow: 0px 4px 34px rgba(24, 24, 24, 0.17);
  }

  &:focus,
  &:active {
    opacity: 0.8;
    box-shadow: 0px 4px 24px rgba(24, 24, 24, 0.24);
  }
}

.default {
  border: 1px solid #d3d7e1;
  height: 50px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #696C73;
  padding: 0 28px;
  background: white;
  border: 1px solid #E2E2E5;

  &:hover {
    border: 1px solid #cdd1da;
  }

  &:focus,
  &:active {
    background: #f8f9f9;
    border: 1px solid #d3d7e1;
    color: #696c73;
  }
}

.defaultGreen {
  border: none;
  height: 50px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #ffffff;
  background: linear-gradient(270deg, #6ebf4c 0%, #78cc54 104.24%);
  padding: 0 28px;
  border: none;

  &:hover {
    background: linear-gradient(270deg, #5cae39 0%, #65ba41 104.24%);
  }

  &:focus,
  &:active {
    background: linear-gradient(270deg, #5cae39 0%, #65ba41 104.24%);
  }
}

.outlined {
  border: 1px solid #d3d7e1;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: white;
  padding: 4px 12px;
  background: transparent;
  border: 1px solid white;
  border-radius: 3px !important;

  &:hover {
    border: 1px solid white;
  }

  &:focus,
  &:active {
    border: 1px solid white;
    color: white;
  }
}

.defaultGreen {
  border: none;
  height: 50px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #ffffff;
  background: linear-gradient(270deg, #6ebf4c 0%, #78cc54 104.24%);
  padding: 0 28px;
  border: none;

  &:hover {
    background: linear-gradient(270deg, #5cae39 0%, #65ba41 104.24%);
  }

  &:focus,
  &:active {
    background: linear-gradient(270deg, #5cae39 0%, #65ba41 104.24%);
  }
}

.button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  transition: all 0.2s ease-out;
  white-space: nowrap;
  outline: none;

  img,
  svg {
    margin-right: 14px;
    max-width: 20px;
    max-height: 20px;
  }

  &:disabled {
    background: #F8F9F9 !important;
    border: 1px solid #D3D7E1 !important;
    box-shadow: none !important;
    color: #696C73 !important;
    cursor: default;
  }
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  margin-left: 0 !important;
  opacity: 0.3;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noPadding {
  padding: 0;
}

.small {
  padding: 0 12px;
  font-weight: normal;
}

.compact {
  padding: 0 21px;
}

.fullSize {
  width: 100%;
}
