.wrapper {
  height: 80px;
  padding: 0 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
}

.wrapper:hover {
  color: white;
}

.wrapper.active {
  color: #6fc04d;
  border-bottom: 4px solid #6fc04d;
}

.content {
  display: flex;
  align-items: center;
  font-size: 20px;

  > img {
    height: 22px;
  }
}

.label {
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
