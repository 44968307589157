@import "variables";

.ant-modal-title {
  font-size: 22px;
  color: #565656;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-header {
  border-radius: 8px;
  border-bottom: 0;
  padding: 38px 24px 24px;
}


.ant-modal-footer {
  border-top: 0;
  padding: 24px 24px 38px 24px;
}

.ant-modal-footer-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: left;
}

.ant-modal-footer-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  .left-col-text {
    font-weight: 500;
    font-size: 18px;
    color: #565656;
    text-align: right;
  }
}


.ant-modal-body {
  padding: 0 24px;
}
