$main-black: #2A3944;
$main-white: #ffffff;
$main-dark: #43291B;
$main-green: #6EBF4C;

$bg-header: #575195;

$font-caption: 'Roboto';
$font-base: 'Roboto';

  // Defining values
$screen-xs-min: 321px;
$screen-xs-max: ($screen-xs-min - 1);
$screen-sm-min: 576px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);
$screen-xl-min: 1920px;
$screen-lg-max: ($screen-lg-min - 1);

/* Usage
@media (max-width: $screen-xs-max) { ... }
@media (min-width: $screen-sm-min) { ... }
@media (max-width: $screen-sm-max) { ... }
@media (min-width: $screen-md-min) { ... }
@media (max-width: $screen-md-max) { ... }
@media (min-width: $screen-lg-min) { ... }
*/

@media (min-width: 1359px) {
  .container {
    max-width: 1300px;
  }
}
