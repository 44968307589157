.container {
  display: inline-block;
  position: relative;
}

.prefix {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.inputWithPrefix {
  padding-left: 22px;
  width: 100%;
}
