@import '../../styles/variables';

.tag {
  padding: 3px 6px;
  display: inline-block;
  height: 17px;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  border-radius: 4px;
  //background: linear-gradient(180deg, #F2A096 0%, #F4B4AB 100%);
  margin-bottom: 5px;
}
