.dropdown {
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
}

.actionNotify {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  letter-spacing: 0.01em;
  height: 50px;
  padding-left: 10px;
  > img {
    margin-right: 10px;
  }
}
