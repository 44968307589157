@import '../../styles/variables';

.loader {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $main-white;
  z-index: 10000;
}

.logo {
  height: 200px;
  width: 200px;
}
