html {
  width: 100vw;
}

body, body {
  height: auto;
  min-height: 100%;
}

.sticky-outer-wrapper.active {
  .sticky-header {
    z-index: 1100;
  }
}

.ant-tabs-nav-scroll {
  padding-left: 5px;
  padding-right: 5px;
}
.ant-tabs-tab {
  color: #8B8D9E;
  background: #FAFAFD!important;
}

.ant-tabs-tab-active {
  color: #2E2E8E!important;
  background: #ffffff!important;
}

.ant-layout {
  background: #f3f3f3;
}

.ant-progress-inner {
  border-radius: 0;
}

.ant-progress-inner {
  background-color: #ffffff;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

.assigment-details-collapse {
  .ant-collapse-header {
    padding-left: 0 !important;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
}

.ant-notification {
  width: 332px;
  .ant-notification-notice {
    padding: 30px 25px;
    &-icon {
      margin-right: 17px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &-message {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #565656;
      margin-bottom: 9px;
      margin-left: 58px;
    }

    &-description {
      font-size: 13px;
      margin-left: 58px;
      line-height: 15px;
      color: #565656;
    }

    &-close {
      display: none;
    }

    &-btn {
      position: absolute;
      top: 6px;
      right: 6px;
      margin-top: 0;
      float: none;
    }
  }
}

.react-datepicker {
  .react-datepicker__time-list-item {
    line-height: 140%;
      }
}

.ant-select {
 .ant-select-selection__rendered {
   line-height: 48px;
 }
}

.ant-radio-checked .ant-radio-inner {
  border: 1px solid  #575195;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: rgb(87, 81, 149);
}
.ant-radio-input:focus + .ant-radio-inner {
  -webkit-box-shadow: 0 0 0 3px rgba(87, 81, 149, 0.08);
          box-shadow: 0 0 0 3px rgba(87, 81, 149, 0.08);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #575195;
}
.ant-checkbox-checked::after {
  border: 1px solid #575195;
}

.ant-radio {
  color: #575195;
  &-inner::after {
    background: #575195;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #575195;
  border-color: #575195;
}

.defaul-colorless-link {
  color: $main-black;
  &:hover {
    color: $main-green;
  }
}

.ant-menu {
  border-radius: 9px;
  overflow: auto;
}

.ant-menu-item {
  color: rgba(0, 0, 0, 0.65);
  &:hover {
    color: rgba(0, 0, 0, 0.65);
  }

  > a {
    color: rgba(0, 0, 0, 0.65);
    &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  &.ant-menu-item-selected {
    background: #f2f1f9 !important;
  }
}

a {
  color: #6FC04D;

  &:hover,
  &:active,
  &:focus {
    color: #6FC04D;
  }
}

// need to makes the badge correct AppHeader
.ant-badge-status-dot {
  width: 12px !important;
  height: 12px !important;
  margin-left: -12px;
}

.ant-rate-disabled {
  .ant-rate-star {
    & > div:hover,
    & > div:active,
    & > div:focus {
      transform: none !important;
    }
  }
}

.ant-message {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  top: initial;
}

.ant-layout-header {
  padding: 0 38px;
}

.react-datepicker-popper {
  z-index: 1100 !important;
}

.DayPicker-Day {
  width: 40px ;
  height: 40px ;
}

.ass-info {
  .DayPicker-Day {
    cursor: default !important;
  };
  .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    cursor: pointer !important;
  }
}

.ant-btn:hover, .ant-btn:focus {
  color: #6ebf4c;
  background-color: #fff;
  border-color: #6ebf4c;
}
