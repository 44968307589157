@import "variables";

.ant-btn-primary {
  background-color: #52c41a;
  border-color: #52c41a;
}

.ant-btn-dashed:active, .ant-btn-dashed.active, .ant-btn-dashed:hover, .ant-btn-dashed:focus {
  border-color: #52c41a !important;
  color: #52c41a !important;
}
