.menu {
  padding: 18px 0px;
  border-radius: 9px;
  border: 1px #f2f1f9;
  position: relative !important;
}

.menuItem {
  position: absolute;
  height: 50px;
  width: 200px;
  font-size: 16px;
  color: #565656;
  display: flex;
  align-items: center;
  padding: 0 15px;

  a,
  .menuAction {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img,
  svg {
    width: 22px;
    margin-right: 16px;
  }

  &:hover,
  &:focus,
  &:active {
    background: #f2f1f9;
  }
}
