.geosuggest {
  position: relative;
}

.geosuggest__suggests {
  background: #ffffff;
  padding-left: 0;
  box-shadow: 0 8px 20px rgba(143,168,191,0.35);
}

.geosuggest__suggests-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10 ;
  background: white;
  width: 100%;
  height: 2px;
}

.geosuggest__item {
  padding-left: 8px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  list-style: none;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item:hover {
  background: rgba(24,144,255,0.2)
}
