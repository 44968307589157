.menu {
  padding: 0px 0px;
  border: 1px #f2f1f9;
  position: relative !important;
  width: 380px;
  max-height: 400px;
  overflow: auto;
  box-shadow: 0px 4px 34px rgba(68, 153, 169, 0.1) !important;
  position: relative;
}

.header {
  padding: 15px 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #EDEDED;
  z-index: 1060;
  display: flex;
  justify-content: flex-end;
  background: white;
}

.notificationsList {
  padding-top: 63px;
}

.notifWrapper {
  width: 100%;
  padding: 15px;
  cursor: pointer;
  :global(.separated) {
    border-bottom: 1px solid #EDEDED;
  }
}

.notifWrapperUnread {
  background-color: #FFFBF2;
}

.dateWrapper {
  height: 20px;
}

.title {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #565656;
}

.titleUnread {
  font-weight: 500;
}

.date {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #8A949A;
}

.button {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #6FC04D;
}

.buttonTitle {
  margin-left: 5px;

}

.button:hover {
  color: #6FC04D;
}

.menuItem {
  position: absolute;
  height: 60px;
  width: 380px;
  font-size: 16px;
  color: #565656;
  display: flex;
  align-items: center;
  padding: 0 0px;

  a, .menuAction {
    display: flex;
    align-items: center;
    justify-content: center;
    img, svg {
      width: 9px;
      margin-right: 16px;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background: #f2f1f9;
  }
}

.dot {
  width: 9px;
  height: 9px;
  background-color: #6EBF4C;
  border-radius: 50%;
}

.spinner {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
}
