.ant-pagination {
  .ant-pagination-item-link {
    border: none;
  }

  .ant-pagination-item {
    border: none;
    border-radius: 50%;
    a {
      color: #696C73;
      text-decoration: none;
    }
  }

  .ant-pagination-item-active {
    background: #696C73;
    a {
      color: white;
    }
  }

  .ant-pagination-disabled {
    display: none;
  }
}

.ant-table-pagination {
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid #EDEDED;
  border-radius: 8px;
  padding: 8px 18px;
}

.ant-table {
  table {
    border-radius: 8px;
  }

  tr > td {
    padding-top: 14px !important;
    padding-bottom: 15px !important;
    font-size: 13px !important;
    color: #565656 !important;

    border-right: none !important;
    &:last-child {
      border-right: 1px solid #e8e8e8 !important;
    }
    border-bottom-color: #EDEDED !important;
  }

  tr > th {
    border-right: none !important;
    background: #F7F8FC !important;
    padding-top: 19px !important;
    padding-bottom: 19px !important;

    &:last-child {
      border-right: 1px solid #e8e8e8 !important;
    }
  }

  .ant-table-thead > tr > th {
    background: #F7F8FC;
  }

  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 8px;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 8px;
  }
  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 8px;
  }
  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 8px;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #F7F8FC;
    .defaul-colorless-link {
      color: $main-green;
    }
  }
}

.address-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;
  margin: 0;
}

.ellipsis-col {
  position: relative;

  &:before {
    content: '&nbsp;';
    visibility: hidden;
  }

  > span, > p, > div {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
