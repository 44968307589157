.backButton {
  cursor: pointer;
  width: 42px;
  height: 42px;
  background: #575195;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
}
