.ant-time-picker-panel-combobox {
  display: flex;
  flex-direction: row;
}

.ant-time-picker-panel-select {
  ul {
    padding: 0;
  }
}

.ant-form {
  .ant-select {
    min-height: 50px;
  }

  .ant-form-item-required::before {
    content: ''
  }

  .ant-form-item {
    margin-bottom: 30px;
  }

  .ant-form-item-label > label {
    font-weight: bold;
    font-size: 13px;
    color: #565656;
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #6FC04D;
  }

  .ant-input-number, &.ant-input-number-focused {
    height: 50px;
    &:hover {
      border-color: #6FC04D;
    }
    &:active, &:focus {
      border-color: #6FC04D;
      outline: none;
      box-sizing: border-box;
      box-shadow: 0px 1px 8px rgba(110, 191, 76, 0.23);
      border-radius: 4px;
    }
  }

  .ant-form-item-children > div {
    width: 100%;
  }

  .ant-input-number-handler-up {
    border-top-right-radius: 8px;
  }

  .ant-input-number-handler-down {
    border-bottom-right-radius: 8px;
  }

  .react-datepicker-wrapper input {
    border-radius: 8px;
  }

  .ant-input-number-handler-wrap {
    border-radius: 0 8px 8px 0;
  }

  .ant-input, .ant-time-picker-input, .ant-input-number, .ant-select-selection, .ant-select-selection--multiple, .DayPickerInput input {
    border: 1px solid #E2E2E5;
    border-radius: 8px;
    font-size: 14px;
    color: #565656;
    min-height: 50px;

    &:not(:first-child) {
      padding-left: 40px;
    }

    .ant-select-selection__placeholder,
    &::placeholder {
      color: #696C73;
    }

    &:active, &:focus, &:hover {
      outline: none;
      background: transparent;
      border: 1px solid #6FC04D !important;
      box-sizing: border-box;
      box-shadow: 0px 1px 8px rgba(110, 191, 76, 0.23);
      border-radius: 8px;
    }
  }

  .ant-select-auto-complete.ant-select .ant-select-selection--single {
    &:hover {
      border:none !important;
      box-shadow: none !important;
    }
  }

  .ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
    line-height: 48px;
  }

  .has-error  {
    .ant-input, .ant-input:hover, .ant-select-selection, .ant-select-selection:hover, .ant-time-picker-input, .ant-input-number, .DayPickerInput input {
      background-color: transparent;
      border: 1px solid  #f5222d !important;
      box-shadow: none !important;
    }
    .ant-select-auto-complete.ant-select .ant-select-selection {
      border:none !important;
      &:hover {
        border:none !important
      }
    }
  }

  .ant-select-selection {
    min-height: 50px;
  }

  .ant-input-number-input {
    min-height: 50px;
  }

  .ant-form-explain {
    font-size: 13px;
    margin-top: 7px;
  }

  .ant-calendar-picker, .ant-time-picker, .ant-input-number {
    width: 100%;
  }
}

.ant-switch-checked {
  background-color: $main-green;
}

.react-datepicker {
  min-width: 328px;
}

.ant-tabs-nav {
  .ant-tabs-tab-active {
    color: #6FC04D;
    &:hover {
      color: #6FC04D;
    }
  }
  .ant-tabs-tab:hover {
    color: #6FC04D;
  }
}


.ant-tabs-ink-bar {
  background-color: #6FC04D;
}

.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
  background-color: #6FC04D !important;
}

.DayPicker-Day--today:not(.DayPicker-Day--selected) {
  color: #575195 !important;
}
